export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const langCodes = ['EN', 'TR', 'EL'];
export const GiftCardPromotionStatus = {
    Inactive: 0,
    Active: 1
};
export const LoyaltyUnits = [
    {
        name: 'Drink',
        value: 0
    },
    {
        name: 'Points',
        value: 1
    },
    {
        name: 'Donut',
        value: 2
    }
];
export const API = {
    GET_lOYALTY_SCHEME: 'loyalty-scheme/admin/get-details/{loyaltySchemeId}',
    GET_PROMOTION: 'promotions/admin/get-details/{promotionId}',
    GET_BUSINESSES_ALL: '/admin/businesses',
    GET_BUSINESS_USERS: '/admin/business/:businessClientId/users',
    SET_PROMOTION_BRANCH: 'admin/branch/promotion',
    GET_BRANCHES_ALL: 'admin/branches',
    DISABLE_BUSINESS_USER: '/admin/business/user/disable',
    ENABLE_BUSINESS_USER: '/admin/business/user/enable',
    CREATE_BUSINESS: '/admin/business/registershop',
    UPDATE_BUSINESS: '/admin/business/update',
    CREATE_BUSINESS_USER: '/admin/business/registeruser',
    CREATE_BUSINESS_BRANCH: '/admin/branch/business',
    GET_CATEGORIES_ALL: '/admin/category/all',
    SET_BRANCH_PROMOTION: 'admin/branch/promotion',
    GENERATE_REPORTS: {
        PROMOTION: '/admin/reports/generate/promotion',
        GIFT_CARD: '/admin/reports/generate/gift-card',
        MEMBERSHIP: '/admin/reports/generate/membership',
        LOYALTY_SCHEME_DUE_AMOUNT: '/admin/reports/generate/loyalty-scheme/due-amount',
        LOYALTY_SCHEME_ENROLLED_USERS: '/admin/reports/generate/loyalty-scheme/enrolled-users',
        LOYALTY_SCHEME_AWARDED_GIFTCARD: '/admin/reports/generate/loyalty-scheme/awarded-giftcard'
    },
    GET_BRANCH_PROMOTIONS: 'promotions/admin/branch/{branchId}',
    GET_SOURCES_FOR_PROMOTION: 'promotions/sources/{promotionId}',
    GET_PROMOTIONS_ALL: '/promotions/all',
    GET_ORDERED_GIFTCARDS: '/admin/order-history/gift-cards/all',
    GET_ORDERED_CHARITIES: '/admin/order-history/donations/all ',
    CREATE_PROMOTION: '/promotions/create',
    UPDATE_PROMOTION: '/promotions/update',
    CREATE_PROMOTION_IMAGE: '/promotions/image/create',
    CREATE_PROMOTION_CATEGORY: '/admin/category',
    UPDATE_PROMOTION_IMAGE: '/promotions/image/update',
    SET_PROMOTION_CATEGORY: '/admin/categoryPromotion',
    GET_PROMOTION_IMAGE: '/promotions/image',
    CREATE_PROMOTIONSOURCE: '/promotions/source/create',
    GET_MEMBERSHIP_PACKAGES_ALL: '/membership-package/all',
    CREATE_MEMBERSHIP_PACKAGE: '/membership-package/create',
    CREATE_MEMBERSHIP_PROMOTION: '/promotions/membership/create',
    GET_GIFTCARD_PROMOTION_ALL: '/gift-card-promotions/admin/all',
    GET_GIFTCARD_PROMOTION: '/gift-card-promotions/admin',
    GET_GIFTCARD_PROMOTION_IMAGE: '/gift-card-promotions/image',
    CREATE_GIFTCARD_PROMOTION: '/gift-card-promotions/admin/create',
    UPDATE_GIFTCARD_PROMOTION: '/gift-card-promotions/admin/update',
    CREATE_GIFTCARD_PROMOTION_IMAGE: '/gift-card-promotions/admin/image/create',
    UPDATE_GIFTCARD_PROMOTION_IMAGE: '/gift-card-promotions/admin/image/update',
    PUSH_NOTIFICATION: '/pushnotification/topic',
    GET_SOURCES: '/source/all',
    GET_PROMOTIONSOURCE_LINKS: '/promotionsource/links',
    GET_LOYALTY_SCHEMES_ALL: '/loyalty-scheme/admin/all',
    GET_LOYALTY_SCHEME_IMAGE: '/loyalty-scheme/image',
    GET_LOYALTY_SCHEME_TRIGGERS: '/loyalty-scheme/admin/triggers',
    CREATE_LOYALTY_SCHEME: '/loyalty-scheme/admin/create',
    UPLOAD_LOYALTY_SCHEME_IMAGE: '/loyalty-scheme/admin/image/upload',
    CREATE_LOYALTY_SCHEME_TRIGGER: '/loyalty-scheme/admin/trigger-reward/create',
    UPDATE_LOYALTY_SCHEME: '/loyalty-scheme/admin/update',
    GET_SHARED_IMAGE: '/shared-image',
    CREATE_SHARED_IMAGE: '/shared-image/create',
    UPDATE_SHARED_IMAGE: '/shared-image/update',
    CREATE_CHARITY: '/charities/admin/charity-campaign/create',
    UPDATE_CHARITY: '/charities/admin/charity-campaign/update',
    DELETE_CHARITY: '/charities/admin/charity-campaign/delete/{charityCampaignId}',
    GET_CHARITIES_ALL: '/charities/admin/charity-campaign/all',
    CREATE_PRINTABLE_GIFTCARD_PRIVIEW: '/printable-gift-card/admin/preview/create'
};
export const MESSAGES = {
    NO_DATA_FOUND: 'No data found',
    NO_RESULTS_FOUND: 'No results found',
    NO_EXISTING_ACCOUNT: 'No user with this email exists'
};

export const FORMATS = {
    DATE_FORMAT: 'DD/MM/YYYY'
};

export const USER_ROLES = {
    ADMIN: 'ADMIN',
    CONTENT_MANAGER: 'CONTENTMANAGER',
    DEVELOPER: 'DEVELOPER'
};

import { USER_ROLES } from 'constants';

const menuItems = [
    {
        id: 'businesses',
        title: 'Businesses',
        url: '/businesses',
        children: [
            {
                id: 'create-business',
                title: 'Create Business',
                url: '/businesses/create-business'
            },
            {
                id: 'create-business-branch',
                title: 'Create Branch',
                url: '/businesses/branches/create'
            },
            {
                id: 'create-business-user',
                title: 'Create Business User',
                url: '/businesses/create-business-user'
            },
            {
                id: 'business',
                url: '/businesses/:business',
                hasDynamicTitle: true
            },
            {
                id: 'set-promotion-branches',
                title: 'Branches',
                url: '/businesses/:id/branches/set-promotion-branches',
                hasDynamicTitle: true
            },
            {
                id: 'view-branch-promotions',
                title: 'Branch Promotions',
                url: '/businesses/:id/branches/:branch',
                hasDynamicTitle: true
            },

            {
                id: 'update-business',
                title: 'Update Business',
                url: '/businesses/edit/:business'
            }
        ]
    },
    {
        id: 'promotions',
        title: 'Promotions',
        url: '/promotions',
        children: [
            {
                isSubmenu: true,
                id: 'all-promotions',
                title: 'All Promotions',
                url: '/promotions'
            },
            {
                isSubmenu: true,
                id: 'promotions-category',
                title: 'Categories',
                url: '/promotions/categories'
            },
            {
                id: 'category-promotions',
                url: '/promotions/categories/:category',
                hasDynamicTitle: true
            },
            {
                id: 'create-promotion',
                title: 'Create Promotion',
                url: '/promotions/create-promotion'
            },
            {
                id: 'promotions-category',
                title: 'Categories',
                url: '/promotions/categories'
            },
            {
                id: 'set-promotion-category',
                title: 'Set Promotion Category',
                url: '/promotions/set-promotion-category'
            },
            {
                id: 'create-promotions-category',
                title: 'Create Category',
                url: '/promotions/create-category'
            },
            {
                id: 'update-promotion',
                title: 'Edit Promotion',
                url: '/promotions/edit/:promotion'
            },
            {
                id: 'view-prommotion',
                url: '/promotions/:promotion',
                hasDynamicTitle: true
            }
        ]
    },

    {
        id: 'charities',
        title: 'Charities',
        url: '/charities',
        children: [
            {
                id: 'all-charities',
                title: 'All Charities',
                url: '/charities'
            },

            {
                id: 'create-charity',
                title: 'Create Charity',
                url: '/promotions/create-charity'
            },

            {
                id: 'update-charity',
                title: 'Edit Charity',
                url: '/charities/edit/:charity'
            },
            {
                id: 'view-charity',
                url: '/charities/:charity',
                hasDynamicTitle: true
            }
        ]
    },

    {
        id: 'memberships',
        title: 'Membership Packages',
        url: '/memberships',
        children: [
            {
                id: 'create-package',
                title: 'Create Membership Package',
                url: '/memberships/create-membership-package'
            }
        ]
    },
    {
        id: 'giftcards',
        title: 'Gift Cards',
        url: '/giftcards',
        children: [
            {
                id: 'view-giftcards',
                title: 'Gift Cards',
                url: '/giftcards'
            },

            {
                id: 'view-giftcard',
                url: '/giftcards/:giftCardId',
                hasDynamicTitle: true
            },
            {
                id: 'create-giftcard',
                title: 'Create Gift Card',
                url: '/giftcards/create-giftcard'
            },
            {
                id: 'update-giftcard',
                title: 'Update Gift Card',
                url: '/giftcards/edit/:giftCardId'
            }
        ]
    },
    {
        id: 'loyalty-schemes',
        title: 'Loyalty Schemes',
        url: '/loyalty-schemes',
        children: [
            {
                id: 'create-loyalty-scheme',
                title: 'Create Loyalty Scheme',
                url: '/loyalty-schemes/create-scheme'
            },
            {
                id: 'create-trigger',
                title: 'Create Trigger',
                url: '/loyalty-schemes/create-trigger'
            },
            {
                id: 'update-loyalty-scheme',
                title: 'Update Loyalty Scheme',
                url: '/loyalty-schemes/edit/:loyaltySchemeId'
            },
            {
                id: 'view-loyalty-scheme',
                url: '/loyalty-schemes/:loyaltySchemeId',
                hasDynamicTitle: true
            }
        ]
    },
    {
        id: 'reports',
        title: 'Reports',
        url: '/reports'
    },
    {
        id: 'orders',
        title: 'Orders',
        url: '/orders',
        children: [
            {
                isSubmenu: true,
                id: 'view-giftcards-orders',
                title: 'Gift Card orders',
                url: '/orders/giftcards'
            },
            {
                isSubmenu: true,
                id: 'view-donation-orders',
                title: 'Donation Orders',
                url: '/orders/donations'
            }
        ],
        inaccessibleRoles: [USER_ROLES.CONTENT_MANAGER]
    },
    {
        id: 'notifications',
        title: 'Push Notifications',
        url: '/push-notifications',
        inaccessibleRoles: [USER_ROLES.CONTENT_MANAGER]
    },
    {
        id: 'printable-giftcards',
        title: 'Printable Gift Cards',
        // todo(delight): update this
        url: '/printable-giftcards/preview/create',
        children: [
            {
                isSubmenu: true,
                id: 'create-printable-giftcards',
                title: 'Create Giftcard Preview',
                url: '/printable-giftcards/preview/create'
            }
        ]
    }
];

export default menuItems;
